<template>
    <div class="w-full sm:w-1/2 md:w-1/2 xl:w-1/4 p-4">
        <div class="block bg-white rounded-lg overflow-hidden">
            <div class="relative pb-48 overflow-hidden">
                <img
                    v-bind:src="card.img"
                    class="absolute inset-0 h-full w-full object-cover rounded-lg"
                    alt=""
                />
            </div>
            <div class="p-4">
                <h2 class="mt-2 mb-2 font-bold">{{ card.title }}</h2>
                <p class="text-sm">
                    {{ card.subTitle }}
                </p>
            </div>
        </div>
    </div>
    <!-- <div class="p-3 w-full sm:w-1/2 md:w-1/2 xl:w-1/4 p-4">
        <div class="bg-white rounded-lg">
            <img
                src="https://images.unsplash.com/photo-1600054800747-be294a6a0d26?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&w=258&q=80"
                alt=""
                class="rounded-lg"
            />
            <div class="p-6">
                <h2 class="font-bold mb-2 text-2xl">
                    {{ card.title }}
                </h2>
                <p class="mb-2">
                    {{ card.subTitle }}
                </p>
            </div>
        </div>
    </div> -->
</template>

<script>
export default {
    name: 'Card',
    props: ['card'],
    data: function () {
        return {}
    },
}
</script>