<template>
    <div class="container mx-auto">
        <!-- hero -->
        <div
            class="my-5 py-10 overflow-hidden shadow-lg rounded-2xl hero"
            id="member-hero"
        >
            <div class="lg:px-20 p-5 text-left text-white">
                <h1 class="font-bold text-4xl">Be Member Of GenBI IPB</h1>
                <p class="pt-8 xl:w-1/2 lg:w-3/4 md:w-full text-justify">
                    Program Beasiswa Bank Indonesia merupakan program beasiswa
                    kepada mahasiswa/i berprestasi di seluruh Indonesia. Per
                    November 2020, beasiswa Bank Indonesia sudah diberikan
                    kepada tersebar 10.545 mahasiswa/i dari 175 Perguruan Tinggi
                    Negeri dan Perguruan Tinggi Swasta di seluruh Indonesia.
                </p>
                <p
                    class="
                        pt-2
                        pb-3
                        xl:w-1/2
                        lg:w-3/4 lg:w-1/2
                        md:w-full
                        text-justify
                    "
                >
                    Program Beasiswa Bank Indonesia diarahkan tidak hanya kepada
                    pemberian dana yang dapat digunakan untuk mendukung biaya
                    pendidikan, tunjangan studi, maupun biaya hidup, namun
                    mahasiswa/i yang terpilih menjadi penerima beasiswa Bank
                    Indonesia kemudian diharapkan dapat memberikan dampak
                    positif kepada masyarakat melalui komunitas Generasi Baru
                    Indonesia (GenBI). Mari bergabung menjadi bagian dari GenBI
                    IPB!
                </p>
                <button class="font-black">Swipe ke bawah 👇</button>
            </div>
        </div>

        <!-- card -->
        <div class="py-10 my-5 flex">
            <div class="flex-grow">
                <div class="">
                    <h1
                        class="md:text-left font-bold text-3xl mb-2"
                        :style="{ color: '#0D528D' }"
                    >
                        Alur Pendaftaran
                    </h1>
                </div>
                <div class="mx-auto pt-10">
                    <div class="flex flex-wrap -mx-4">
                        <!-- card component -->
                        <app-card
                            v-for="card in cards"
                            :key="card.id"
                            :card="card"
                        />
                    </div>
                </div>
            </div>
        </div>

        <!-- beasiswa genbi ipb -->
        <div class="py-10 md:text-left my-5 flex">
            <div class="flex-grow">
                <div class="">
                    <h1
                        class="font-bold text-3xl mb-2"
                        :style="{ color: '#0D528D' }"
                    >
                        Beasiswa GenBI IPB
                    </h1>
                    <p class="sm:w-full md:w-full xl:w-1/2 py-3">
                        Beasiswa GenBI dibagi menjadi 3 yaitu Beasiswa S1
                        Unggulan, Beasiswa S1 Reguler, dan Beasiswa Diploma.
                    </p>
                    <div class="flex flex-wrap">
                        <!-- beasiswa s1 unggulan -->

                        <div
                            class="
                                w-full
                                sm:w-1/2
                                md:w-1/2
                                xl:w-1/4
                                flex-auto
                                pr-5
                            "
                        >
                            <router-link to="/unggulan" exact>
                                <div class="bg-white rounded-lg shadow-md">
                                    <div class="p-6">
                                        <h2 class="font-bold mb-2 text-2xl">
                                            Beasiswa S1 Unggulan
                                        </h2>

                                        <p class="mb-2">
                                            {{ unggulan.started }} -
                                            {{ unggulan.ended }}
                                        </p>
                                        <div class="flex pt-5">
                                            <img
                                                src="https://lh3.googleusercontent.com/3-nZmufwTVECMGoHwXjU6AuEg0y2-3e9zRj-TsdgXwa1RK-PpNfEjSOK2Pe-8Yvr9d8Do_pHkokHGIjVnYHAXAqgvkWqJFZU9Ih1AJ2-AOl0usSmyp30lNw7op1g8XgGLgmUL2H7oQ=s16-p-k"
                                                alt=""
                                                class="rounded-full h-5 w-5"
                                            />
                                            <p class="pl-2 mb-2">
                                                {{ unggulan.status }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </router-link>
                        </div>

                        <!-- beasiswa s1 reguler -->

                        <div
                            class="
                                w-full
                                sm:w-1/2
                                md:w-1/2
                                xl:w-1/4
                                flex-auto
                                pr-5
                            "
                        >
                            <router-link to="/reguler" exact>
                                <div class="bg-white rounded-lg shadow-md">
                                    <div class="p-6">
                                        <h2 class="font-bold mb-2 text-2xl">
                                            Beasiswa S1 Reguler
                                        </h2>

                                        <p class="mb-2">
                                            {{ reguler.started }} -
                                            {{ reguler.ended }}
                                        </p>
                                        <div class="flex pt-5">
                                            <img
                                                src="https://lh3.googleusercontent.com/3-nZmufwTVECMGoHwXjU6AuEg0y2-3e9zRj-TsdgXwa1RK-PpNfEjSOK2Pe-8Yvr9d8Do_pHkokHGIjVnYHAXAqgvkWqJFZU9Ih1AJ2-AOl0usSmyp30lNw7op1g8XgGLgmUL2H7oQ=s16-p-k"
                                                alt=""
                                                class="rounded-full h-5 w-5"
                                            />
                                            <p class="pl-2 mb-2">
                                                {{ reguler.status }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </router-link>
                        </div>

                        <!-- diploma -->

                        <div
                            class="w-full sm:w-1/2 md:w-1/2 xl:w-1/4 flex-auto"
                        >
                            <router-link to="/diploma" exact>
                                <div class="bg-white rounded-lg shadow-md">
                                    <div class="p-6">
                                        <h2 class="font-bold mb-2 text-2xl">
                                            Beasiswa Diploma
                                        </h2>

                                        <p class="mb-2">
                                            {{ diploma.started }} -
                                            {{ diploma.ended }}
                                        </p>
                                        <div class="flex pt-5">
                                            <img
                                                src="https://lh3.googleusercontent.com/3-nZmufwTVECMGoHwXjU6AuEg0y2-3e9zRj-TsdgXwa1RK-PpNfEjSOK2Pe-8Yvr9d8Do_pHkokHGIjVnYHAXAqgvkWqJFZU9Ih1AJ2-AOl0usSmyp30lNw7op1g8XgGLgmUL2H7oQ=s16-p-k"
                                                alt=""
                                                class="rounded-full h-5 w-5"
                                            />
                                            <p class="pl-2 mb-2">
                                                {{ diploma.status }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- persyaratan pendaftaran -->
        <div class="pt-10 md:text-left my-5 flex content-end">
            <div class="flex-auto">
                <div class="pb-3">
                    <h1
                        class="font-bold text-3xl mb-2"
                        :style="{ color: '#0D528D' }"
                    >
                        Tabel Persyaratan Pendaftaran
                    </h1>
                </div>
            </div>
        </div>

        <!-- grid table persyaratan pendaftaran lg-->
        <div class="grid md:text-left grid-cols-4 grid-flow-row pb-10">
            <div
                class="
                    bg-blue-50
                    border-t-2 border-l-2 border-blue-400
                    rounded-tl-lg
                    text-blue-500
                    font-bold
                    p-3
                "
            ></div>
            <div
                class="
                    bg-blue-50
                    border-t-2 border-l-2 border-blue-400
                    text-blue-500
                    font-bold
                    p-3
                "
            >
                S1 Unggulan
            </div>
            <div
                class="
                    bg-blue-50
                    border-t-2 border-l-2 border-blue-400
                    text-blue-500
                    font-bold
                    p-3
                "
            >
                S1 Reguler
            </div>
            <div
                class="
                    bg-blue-50
                    border-t-2 border-l-2 border-r-2 border-blue-400
                    rounded-tr-lg
                    text-blue-500
                    font-bold
                    p-3
                "
            >
                Diploma
            </div>

            <div
                class="
                    bg-blue-50
                    border-t-2 border-l-2 border-blue-400
                    text-blue-500
                    font-bold
                    p-3
                "
            >
                Program
            </div>
            <div class="border-t-2 border-l-2 border-blue-400 p-3">S1</div>
            <div class="border-t-2 border-l-2 border-blue-400 p-3">S1</div>
            <div class="border-t-2 border-l-2 border-r-2 border-blue-400 p-3">
                D3
            </div>

            <div
                class="
                    bg-blue-50
                    border-t-2 border-l-2 border-blue-400
                    text-blue-500
                    font-bold
                    p-3
                "
            >
                Cuti Akademik
            </div>
            <div class="border-t-2 border-l-2 border-blue-400 p-3">
                Tidak sedang cuti akademik
            </div>
            <div class="border-t-2 border-l-2 border-blue-400 p-3">
                Tidak sedang cuti akademik
            </div>
            <div class="border-t-2 border-l-2 border-r-2 border-blue-400 p-3">
                Tidak sedang cuti akademik
            </div>

            <div
                class="
                    bg-blue-50
                    border-t-2 border-l-2 border-blue-400
                    text-blue-500
                    font-bold
                    p-3
                "
            >
                SKL
            </div>
            <div class="border-t-2 border-l-2 border-blue-400 p-3">
                Belum turun
            </div>
            <div class="border-t-2 border-l-2 border-blue-400 p-3">
                Belum turun
            </div>
            <div class="border-t-2 border-l-2 border-r-2 border-blue-400 p-3">
                Belum turun
            </div>

            <div
                class="
                    bg-blue-50
                    border-t-2 border-l-2 border-blue-400
                    text-blue-500
                    font-bold
                    p-3
                "
            >
                Minimal SKS
            </div>
            <div class="border-t-2 border-l-2 border-blue-400 p-3">40</div>
            <div class="border-t-2 border-l-2 border-blue-400 p-3">40</div>
            <div class="border-t-2 border-l-2 border-r-2 border-blue-400 p-3">
                40
            </div>

            <div
                class="
                    bg-blue-50
                    border-t-2 border-l-2 border-blue-400
                    text-blue-500
                    font-bold
                    p-3
                "
            >
                Minimal Semester
            </div>
            <div class="border-t-2 border-l-2 border-blue-400 p-3">4</div>
            <div class="border-t-2 border-l-2 border-blue-400 p-3">4</div>
            <div class="border-t-2 border-l-2 border-r-2 border-blue-400 p-3">
                4
            </div>

            <div
                class="
                    bg-blue-50
                    border-t-2 border-l-2 border-blue-400
                    text-blue-500
                    font-bold
                    p-3
                "
            >
                Minimal IPK
            </div>
            <div class="border-t-2 border-l-2 border-blue-400 p-3">3.50</div>
            <div class="border-t-2 border-l-2 border-blue-400 p-3">3.00</div>
            <div class="border-t-2 border-l-2 border-r-2 border-blue-400 p-3">
                3.00
            </div>

            <div
                class="
                    bg-blue-50
                    border-t-2 border-l-2 border-blue-400
                    text-blue-500
                    font-bold
                    p-3
                "
            >
                Usia maksimal saat menerima beasiswa
            </div>
            <div class="border-t-2 border-l-2 border-blue-400 p-3">23</div>
            <div class="border-t-2 border-l-2 border-blue-400 p-3">23</div>
            <div class="border-t-2 border-l-2 border-r-2 border-blue-400 p-3">
                23
            </div>

            <div
                class="
                    bg-blue-50
                    border-t-2 border-l-2 border-blue-400
                    text-blue-500
                    font-bold
                    p-3
                "
            >
                Kemampuan Bahasa Inggris
            </div>
            <div class="border-t-2 border-l-2 border-blue-400 p-3">
                TOEFL > 500 atau IELTS > 6.0
            </div>
            <div class="border-t-2 border-l-2 border-blue-400 p-3">
                Tidak perlu sertifikat Bahasa Inggris
            </div>
            <div class="border-t-2 border-l-2 border-r-2 border-blue-400 p-3">
                Tidak perlu sertifikat Bahasa Inggris
            </div>

            <div
                class="
                    bg-blue-50
                    border-t-2 border-l-2 border-blue-400
                    text-blue-500
                    font-bold
                    p-3
                "
            >
                Besaran beasiswa
            </div>
            <div class="border-t-2 border-l-2 border-blue-400 p-3">
                Rp1.500.000,00 per bulan
            </div>
            <div class="border-t-2 border-l-2 border-blue-400 p-3">
                Rp1.000.000,00 per bulan
            </div>
            <div class="border-t-2 border-l-2 border-r-2 border-blue-400 p-3">
                Rp1.000.000,00 per bulan
            </div>

            <div
                class="
                    bg-blue-50
                    border-t-2 border-l-2 border-blue-400
                    text-blue-500
                    font-bold
                    p-3
                "
            >
                Periode beasiswa
            </div>
            <div class="border-t-2 border-l-2 border-blue-400 p-3">
                4 semester
            </div>
            <div class="border-t-2 border-l-2 border-blue-400 p-3">
                4 semester
            </div>
            <div class="border-t-2 border-l-2 border-r-2 border-blue-400 p-3">
                4 semester
            </div>

            <div
                class="
                    bg-blue-50
                    border-t-2 border-l-2 border-blue-400
                    text-blue-500
                    font-bold
                    p-3
                "
            >
                Cakupan Beasiswa
            </div>
            <div class="border-t-2 border-l-2 border-blue-400 p-3">
                Pelatihan penulisan, public speaking, & leadership camp. <br />
                Kegiatan social project & pengalaman networking.
            </div>
            <div class="border-t-2 border-l-2 border-blue-400 p-3">
                Pelatihan penulisan, public speaking, & leadership camp. <br />
                Kegiatan social project & pengalaman networking.
            </div>
            <div class="border-t-2 border-l-2 border-r-2 border-blue-400 p-3">
                Pelatihan penulisan, public speaking, & leadership camp. <br />
                Kegiatan social project & pengalaman networking.
            </div>

            <div
                class="
                    bg-blue-50
                    row-span-3
                    border-t-2 border-l-2 border-b-2 border-blue-400
                    rounded-bl-lg
                    text-blue-500
                    font-bold
                    p-3
                "
            >
                Lainnya
            </div>
            <div
                class="
                    col-span-3
                    border-t-2 border-l-2 border-r-2 border-blue-400
                    p-3
                "
            >
                Tidak sedang menerima beasiswa atau berada dalam ikatan dengan
                lembaga/instansi lain, serta bersedia tidak menerima beasiswa
                lain selama menerima beasiswa BI.
            </div>

            <div
                class="
                    col-span-3
                    border-t-2 border-l-2 border-r-2 border-blue-400
                    p-3
                "
            >
                Bersedia berperan aktif dalam Generasi Baru Indonesia (GenBI)
                dan berpartisipatif dalam kegiatan yang diselenggarakan Bank
                Indonesia.
            </div>

            <div class="col-span-3 border-2 border-blue-400 rounded-br-lg p-3">
                Memiliki minat dan pengalaman menjalankan aktivitas sosial yang
                mempunyai dampak kebermanfaatan bagi masyarakat.
            </div>
        </div>

        <!-- dokumen pendaftaran -->
        <div class="pt-10 md:text-left my-5 flex content-end">
            <div class="flex-auto">
                <div class="pb-3">
                    <h1
                        class="font-bold text-3xl mb-2"
                        :style="{ color: '#0D528D' }"
                    >
                        Tabel Dokumen Pendaftaran
                    </h1>
                </div>
            </div>
        </div>

        <!--grid table dokumen pendaftaran lg -->
        <div class="grid md:text-left grid-cols-4 grid-flow-row pb-10">
            <div
                class="
                    bg-blue-50
                    border-t-2 border-l-2 border-blue-400
                    rounded-tl-lg
                    text-blue-500
                    font-bold
                    p-3
                "
            ></div>
            <div
                class="
                    bg-blue-50
                    border-t-2 border-l-2 border-blue-400
                    text-blue-500
                    font-bold
                    p-3
                "
            >
                S1 Unggulan
            </div>
            <div
                class="
                    bg-blue-50
                    border-t-2 border-l-2 border-blue-400
                    text-blue-500
                    font-bold
                    p-3
                "
            >
                S1 Reguler
            </div>
            <div
                class="
                    bg-blue-50
                    border-t-2 border-l-2 border-r-2 border-blue-400
                    rounded-tr-lg
                    text-blue-500
                    font-bold
                    p-3
                "
            >
                Diploma
            </div>

            <div
                class="
                    bg-blue-50
                    border-t-2 border-l-2 border-blue-400
                    text-blue-500
                    font-bold
                    p-3
                "
            >
                Fotocopy KTP dan KTM
            </div>
            <div class="border-t-2 border-l-2 border-blue-400 p-3">Ya</div>
            <div class="border-t-2 border-l-2 border-blue-400 p-3">Ya</div>
            <div class="border-t-2 border-l-2 border-r-2 border-blue-400 p-3">
                Ya
            </div>

            <div
                class="
                    bg-blue-50
                    border-t-2 border-l-2 border-blue-400
                    text-blue-500
                    font-bold
                    p-3
                "
            >
                Transkrip nilai semester terakhir
            </div>
            <div class="border-t-2 border-l-2 border-blue-400 p-3">Ya</div>
            <div class="border-t-2 border-l-2 border-blue-400 p-3">Ya</div>
            <div class="border-t-2 border-l-2 border-r-2 border-blue-400 p-3">
                Ya
            </div>

            <div
                class="
                    bg-blue-50
                    border-t-2 border-l-2 border-blue-400
                    text-blue-500
                    font-bold
                    p-3
                "
            >
                SKTM
            </div>
            <div class="border-t-2 border-l-2 border-blue-400 p-3">
                Tidak wajib
            </div>
            <div class="border-t-2 border-l-2 border-blue-400 p-3">
                Tidak wajib
            </div>
            <div class="border-t-2 border-l-2 border-r-2 border-blue-400 p-3">
                Tidak wajib
            </div>

            <div
                class="
                    bg-blue-50
                    border-t-2 border-l-2 border-blue-400
                    text-blue-500
                    font-bold
                    p-3
                "
            >
                Surat rekomendasi
            </div>
            <div class="border-t-2 border-l-2 border-blue-400 p-3">
                Akademik & Non-akademik
            </div>
            <div class="border-t-2 border-l-2 border-blue-400 p-3">
                Akademik & Non-akademik
            </div>
            <div class="border-t-2 border-l-2 border-r-2 border-blue-400 p-3">
                Akademik
            </div>

            <div
                class="
                    bg-blue-50
                    border-t-2 border-l-2 border-blue-400
                    text-blue-500
                    font-bold
                    p-3
                "
            >
                Motivation letter
            </div>
            <div class="border-t-2 border-l-2 border-blue-400 p-3">
                1 lembar dalam Bahasa Inggris
            </div>
            <div class="border-t-2 border-l-2 border-blue-400 p-3">
                1 lembar dalam Bahasa Indonesia
            </div>
            <div class="border-t-2 border-l-2 border-r-2 border-blue-400 p-3">
                1 lembar dalam Bahasa Indonesia
            </div>

            <div
                class="
                    bg-blue-50
                    border-t-2 border-l-2 border-blue-400
                    text-blue-500
                    font-bold
                    p-3
                "
            >
                Resume pribadi
            </div>
            <div class="border-t-2 border-l-2 border-blue-400 p-3">Ya</div>
            <div class="border-t-2 border-l-2 border-blue-400 p-3">Ya</div>
            <div class="border-t-2 border-l-2 border-r-2 border-blue-400 p-3">
                Ya
            </div>

            <div
                class="
                    bg-blue-50
                    border-t-2 border-l-2 border-blue-400
                    text-blue-500
                    font-bold
                    p-3
                "
            >
                Career path
            </div>
            <div class="border-t-2 border-l-2 border-blue-400 p-3">
                1 lembar dalam Bahasa Inggris
            </div>
            <div class="border-t-2 border-l-2 border-blue-400 p-3">
                Tidak perlu
            </div>
            <div class="border-t-2 border-l-2 border-r-2 border-blue-400 p-3">
                Tidak perlu
            </div>

            <div
                class="
                    bg-blue-50
                    border-t-2 border-l-2 border-blue-400
                    text-blue-500
                    font-bold
                    p-3
                "
            >
                Slip gaji orang tua
            </div>
            <div class="border-t-2 border-l-2 border-blue-400 p-3">Ya</div>
            <div class="border-t-2 border-l-2 border-blue-400 p-3">Ya</div>
            <div class="border-t-2 border-l-2 border-r-2 border-blue-400 p-3">
                Ya
            </div>

            <div
                class="
                    bg-blue-50
                    border-t-2 border-l-2 border-b-2 border-blue-400
                    rounded-bl-lg
                    text-blue-500
                    font-bold
                    p-3
                "
            >
                Pengisian data di SIMAWA IPB
            </div>
            <div class="border-t-2 border-l-2 border-b-2 border-blue-400 p-3">
                Ya
            </div>
            <div class="border-t-2 border-l-2 border-b-2 border-blue-400 p-3">
                Ya
            </div>
            <div
                class="
                    border-t-2 border-l-2 border-b-2 border-r-2 border-blue-400
                    rounded-br-lg
                    p-3
                "
            >
                Ya
            </div>
        </div>

        <div
            class="
                px-10
                mb-40
                bg-ready
                content-center
                overflow-hidden
                rounded-2xl
            "
        >
            <h2
                class="lg:px-48 lg:pt-20 pt-16 pb-12 font-bold text-3xl"
                :style="{ color: '#0D528D' }"
            >
                Kamu siap memberikan energimu untuk membangun negeri bersama
                GenBI IPB?
            </h2>

            <!-- <button
                @click="goToFAQ"
                class="rounded-full px-5 py-2 bg-blue-500 hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-10 text-white mr-5"
            >
                Open FAQs
            </button>

            <button
                @click="goToContact"
                class="rounded-full px-5 py-2 bg-white border-2 border-blue-500 hover:bg-opacity-80 focus:outline-black focus:ring-2 focus:ring-purple-600 focus:ring-opacity-10 text-blue-500"
            >
                Contact Person
            </button> -->
            <a href="/faq" class="px-5">
                <button
                    class="
                        rounded-full
                        px-5
                        py-2
                        bg-blue-500
                        hover:bg-opacity-80
                        focus:outline-none
                        focus:ring-2 focus:ring-purple-600 focus:ring-opacity-10
                        text-white
                    "
                >
                    Open FAQs
                </button>
            </a>

            <a href="/contact" class="px-5">
                <button
                    class="
                        rounded-full
                        px-5
                        py-2
                        bg-white
                        border-2 border-blue-500
                        hover:bg-opacity-80
                        focus:outline-black
                        focus:ring-2 focus:ring-purple-600 focus:ring-opacity-10
                        text-blue-500
                    "
                >
                    Contact Person
                </button>
            </a>
        </div>
    </div>
</template>

<script>
import Card from '../components/Card'
import axios from 'axios'
import moment from 'moment'

export default {
    components: {
        'app-card': Card,
    },
    // mounted() {
    //     window.scroll(0, 0)
    //     this.getDate()
    // },
    data: function () {
        return {
            // unggulan: {
            //     // start: '',
            //     // end: '',
            //     // status: '',
            // },
            // reguler: {},
            // diploma: {},
            cards: [
                {
                    id: 1,
                    title: '1. Pendaftaran Berkas',
                    subTitle:
                        'Pendaftaran & Pengumpulan Berkas melalui simawa.ipb.ac.id',
                    img: 'https://lh3.googleusercontent.com/wpyNHWkAD_lj5Tx6Tm3qMrjgRpocGb7wbiDqt5vW_Ae9NDvsTuwkO3vIOaQdlaO0fRUwpALq89lUifdmfeHjp8qeUUu1_3AYa6-JWo2Jh9jiCDnYR8z1Z_XuvtRdi-4qeMg5ehapqQ=s258-p-k',
                },
                {
                    id: 2,
                    title: '2. Seleksi Tahap 1',
                    subTitle:
                        'Seleksi tahap 1 dilakukan dengan menyeleksi berkas',
                    img: 'https://lh3.googleusercontent.com/5s2GtkheB8SeGzu1ah_JAKBahfUssd7Sa0PZ8hSC9UgOPbVEKaFLwyCdJv4Jnp2X5tfy0QwUXl1pUZrg7lySDiLO0IMLt2vfMhRChDS02Jy-g1ZPbnhYE9O3nxiziZM7G-VTxdFy7g=s258-p-k',
                },
                {
                    id: 3,
                    title: '3. Seleksi Tahap 2',
                    subTitle:
                        'Calon GenBI IPB akan diwawancara untuk penyeleksian tahap 2',
                    img: 'https://lh3.googleusercontent.com/7ACK0upfJyLk82btKLfeWZTOyhcSFOqSx294t78FLaICm1vUqbsdTJVlbXRnka-F5V94ByZxW6zG94vm1C7DuNXLsg7uca_2DDZLdM0Ce2FQFisJPLdt-RZk5bQpi7JGkF3ceWyHXQ=s258-p-k',
                },
                {
                    id: 4,
                    title: '4. Pengumuman',
                    subTitle: 'Pengumuman penerima beasiswa BI IPB',
                    img: 'https://lh3.googleusercontent.com/Au5djNJizD2IfZCPuk4SpHr1vnsvSlIYz32ukN8O6wumoa9MVYUffWXfZ_H7rRXTYpBwkjzgU0Um5144f6nJH17LDuQV1UDH84L392XULK6cH7rzP22OTtrSJLV3r76iBxZ_8sJwHg=s258-p-k',
                },
            ],
            unggulan: [],
            reguler: [],
            diploma: [],
            error: null,
        }
    },
    changeDateFormat: function (date) {
        return moment(date, 'YYYY-MM-DD').format('DD-MMM-YY')
    },
    async mounted() {
        try {
            // connect to strapi
            const res = await axios.get(
                'https://strapi-genbi.herokuapp.com/schedules'
            )
            this.unggulan = res.data[0]
            this.reguler = res.data[1]
            this.diploma = res.data[2]

            this.unggulan.started = moment(
                this.unggulan.started,
                'YYYY-MM-DD'
            ).format('DD MMM YYYY')

            this.unggulan.ended = moment(
                this.unggulan.ended,
                'YYYY-MM-DD'
            ).format('DD MMM YYYY')

            this.reguler.started = moment(
                this.reguler.started,
                'YYYY-MM-DD'
            ).format('DD MMM YYYY')

            this.reguler.ended = moment(
                this.reguler.ended,
                'YYYY-MM-DD'
            ).format('DD MMM YYYY')

            this.diploma.started = moment(
                this.diploma.started,
                'YYYY-MM-DD'
            ).format('DD MMM YYYY')

            this.diploma.ended = moment(
                this.diploma.ended,
                'YYYY-MM-DD'
            ).format('DD MMM YYYY')
        } catch (error) {
            this.error = error
        }
    },
    metaInfo: {
        title: 'GenBI IPB',
        titleTemplate: `%s - Jadi member GenBI IPB`,
        htmlAttrs: {
            lang: 'id',
            amp: true,
        },
        meta: [
            { charset: 'utf-8' },
            {
                name: 'description',
                content:
                    'Program Beasiswa Bank Indonesia merupakan program beasiswa kepada mahasiswa/i berprestasi di seluruh Indonesia. Per November 2020, beasiswa Bank Indonesia sudah diberikan kepada tersebar 10.545 mahasiswa/i dari 175 Perguruan Tinggi Negeri dan Perguruan Tinggi Swasta di seluruh Indonesia.',
            },
            {
                name: 'viewport',
                content: 'width=device-width, initial-scale=1',
            },
        ],
    },
    methods: {
        getStatus(status) {
            switch (status) {
                case 'BUKA':
                    return 'Pendaftaran Dibuka'
                case 'TUTUP':
                    return 'Pendaftaran Ditutup'
                case 'MENUNGGU':
                    return 'Pendaftaran Belum Dibuka'
            }
        },
        goToFAQ() {
            this.$router.push('/faq')
        },
        goToContact() {
            this.$router.push('/kontak')
        },
        async getDate() {
            // used later
            // const { data } = await axios.get(
            //     'https://api.backendless.com/1BFCE5AC-540E-AC5C-FF52-59629EC7E000/C3E94AD5-075F-4391-8E10-88B3FD4E31BF/services/websitegenbi/registration-dates'
            // )
            // console.log(data)
            // data.forEach(date => {
            //     // console.log(date.type)
            //     switch (date.type) {
            //         case 'unggulan':
            //             this.unggulan = {
            //                 start: moment(date.start_date).format(
            //                     'DD MMMM YYYY'
            //                 ),
            //                 end: moment(date.end_date).format('DD MMMM YYYY'),
            //                 status: date.status,
            //             }
            //             break
            //         case 'diploma':
            //             this.diploma = {
            //                 start: moment(date.start_date).format(
            //                     'DD MMMM YYYY'
            //                 ),
            //                 end: moment(date.end_date).format('DD MMMM YYYY'),
            //                 status: date.status,
            //             }
            //             break
            //         case 'reguler':
            //             this.reguler = {
            //                 start: moment(date.start_date).format(
            //                     'DD MMMM YYYY'
            //                 ),
            //                 end: moment(date.end_date).format('DD MMMM YYYY'),
            //                 status: date.status,
            //             }
            //             break
            //     }
            // })
        },
    },
}
</script>

<style>
.bg-ready {
    background-image: url('https://lh3.googleusercontent.com/4_sp0gKesvznH7_sr6_uWRL6x9_JQmFJAY1jBMNj4frqGbLSYciAV0v7cFLiSvs3502YXhJQjtI2HqhtUpVVYNwvmveDbZ4T8aMdTTUHMIeXN3ZN8B6FZ7ib_KCZIQxvRVoDLApd6_1DvlvCql5LWfU_EIFJa8lLFKocLgQwhEYjojr_ADldTqRNLfZq8JSG4tHuA7ypphS_Qx4WAPlY12ZVH7FYDkw_Da5dmTYv4PpNBjrUUufqAaWVCovKu1TKiR0aAHiWo34rZkTV0qr88MRXqYZ2o0FHDfLf30qBo9ZmSgj3UGPboELV4_auVEEZz1v1YHdlNR97S3BOmVSoDtm5QP7kS2aZIblwMi9Kl9P-yVSShqGQxICzh5gyjuNQBH1oxEPNXIFBBIx2F65yHcI_i6MolkelbpWsZhGOpwNEv9PUfhuR2DbIErEE52WOgNIMxZPG2oAa0BZZlyCoSQ1DR3Wm56RVGjyvg14QuvW1NsBf83IrI8_KyG2j6AmbQfigqdk8BUiUjLAQ-D-5xkIzfnU1I3NNmpDiFFHiLSK9_Ii5Z7Rr3xmQDJfHEXZlQ9Vcpf2iOBw99LlE4TH6iBhWrVJCWzx1N-jZEN82AbbYQU-_DKz-0FIwenOBH4gneqtw06sc6TnHcy8yHvpay3AoelzlbhPChsAzFG6uCC-QkblKnIjWFm9ue0PB5z_QqDiXJvVMl-4GZ8-W169JCGr9=w2400');
    background-size: 82.5rem;
    min-height: 320px;
    background-position: center;
    background-repeat: no-repeat;
}

#member-hero {
    /* background-position: 20px 10px 20px 20px; */
    background-image: linear-gradient(
            to left,
            rgba(255, 255, 255, 0),
            rgba(0, 0, 0, 0.8)
        ),
        url('https://lh3.googleusercontent.com/8KEolEZyzWZQMdWmjFef5uZRKBE4BDk9fysOtGRCzdM9mm_eFbguWaB3pkkJwG1bq8TtWPM4VA63hW9orLyrfZBar38IJEnVnaX5G2Y7VEilQkIKqeTeD8_6ccro0_cyBGjGKIDwvA=w2400');
    /* url('https://lh3.googleusercontent.com/jHoquMAVj5LblRU5S1fBreNRvupGSRM-WegPAiqNx586852TO1Q5AMsbxQO2-2ISAbxUotUTtfzAZ93iStNqEZsj-rysYqJX3JD1iiqznXmnaDkitKJy6g0hfuy57sdezztLynmXaw=w2400'); */
}
</style>
